@import "./responsive.scss";
@import "./mixin.scss";
// *{
//   outline: white 1px solid;
// }
body {
  background: black;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px;
}

.titlePrestations, .titleWhy {
  font-weight: 900;
  font-size: 30px;
  margin-left: 5px;
  padding: 50px;
}


@media #{$small-down} {

  .sectionPrestations, .sectionWhy {
    box-shadow:  none;
    outline: 1px solid $colorTitle;
  }
}


// :root{
//   --bs-dark: #212529;
// }

.theme-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.theme-container:hover {
  opacity: 0.8;
}
//icone dark et light
.shadow-dark {
  
  box-shadow: 17px 17px 23px #1a1d20,
    -17px -17px 23px #282d32, inset 5px 5px 4px #1e2226,
    inset -5px -5px 4px #24282c;
}
.shadow-light {
    box-shadow: 7px 7px 15px -10px #bbcfda, -4px -4px 13px #ffffff,
    inset 7px 7px 3px rgba(209, 217, 230, 0.35),
    inset -11px -11px 3px rgba(255, 255, 255, 0.3)
}

@keyframes change {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.4);
  }
}

.change {
  animation-name: change;
  animation-duration: 1s;
  animation-direction: alternate;
}

// theme html
html.dark{
  background-color:black;
  .titlePrestations, .titleWhy {
    color: white;}
}
html.light{
  background-color:white;
  .titlePrestations, .titleWhy {
    color: black;}
}

/* NAVIGATE Border from Y to X  */
div.borderYtoX a:before,
div.borderYtoX a:after {
  position: absolute;
  opacity: 0.5;
  height: 100%;
  width: 2px;
  content: "";
  transition: all 0.3s;
}

div.borderYtoX a:before {
  left: 0px;
  top: 0px;
}

div.borderYtoX a:after {
  right: 0px;
  bottom: 0px;
}

div.borderYtoX a:hover:before,
div.borderYtoX a:hover:after {
  opacity: 1;
  height: 2px;
  width: 100%;
}
