@import "../../style/communs.scss";
.sectionWhy{
 // border: 3px red solid;
display: flex;
padding-top: 90px;
padding-bottom: 90px;
}
.cardWhy-basic {
 
  margin: 0 2rem 1rem 0;
  padding: 0 0 0.5rem 0;
  width: 300px;
  background: white;
  color: #444;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.2);
  overflow: hidden;
   transition: .2s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
}

 .cardWhy-basic:hover{

  transform: scale(-1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}



.cardWhy-headerWhy {
  height: 5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  padding: 1rem 0;
  color: #fff;
  
}

.headerWhy-basic {
  background: linear-gradient(135deg, gray, black);
  height: 100px;
}
.colorTitleWhy{
  padding: 8px;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 18px;
}
.cardWhy-bodyWhy {
  padding: 0.5rem 0;
}
.cardWhy-bodyWhy h2 {
  font-size: 2rem;
  font-weight: 700;
}

.cardWhy-element-containerWhy {
  color: black;
  //list-style: none;
  line-height: 1.8;
  font-size: 13px;
  text-align: justify;
  padding: 5px;
}

.cardWhy-element-hidden {
  display: none;
}
.cardWhy-element{
padding: 10px;
}

@media #{$small-down} {
  .sectionWhy{
    padding-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content:center;
    padding-left: 30px;
    }

}