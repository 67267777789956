@import "../../style/communs.scss";

.sectionPrestations{
   display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
height: 800px;
margin-bottom: 30px;
//border: 3px red solid;

}

.cardPrestation {
    position: relative;
    max-width:200px ;
    height: 260px;
    margin-right: 20px;
    background: gray;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
     padding: 40px 20px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .2);
    transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    overflow: hidden;
   
}

.cardPrestation:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}


.cardPrestation:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: rgba(255, 255, 255, .1);
    z-index: 1;
    transform: skewY(-5deg) scale(1.5);
    -webkit-transform: skewY(-5deg) scale(1.5);
    -moz-transform: skewY(-5deg) scale(1.5);
    -ms-transform: skewY(-5deg) scale(1.5);
    -o-transform: skewY(-5deg) scale(1.5);
}

.titlePrestation .fa {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    text-align: center;
    line-height: 100px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .2);
    
}

.titlePrestation{
    color: #fff;
}

.pricePrestation {
    margin: 0;
    padding: 20px 0;
    color: #fff;
    font-size: 20px;
}


.optionPrestation ul {
    margin: 0;
    padding: 0;
}

.optionPrestation ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    color: #fff;
    text-align: justify;
    font-size: 13px;
}

.cardPrestation a {
    position: relative;
    z-index: 2;
    background: #fff;
    color: #262626;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    display: block;
    text-align: justify;
    margin: 20px auto 0;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.cardPrestation a:hover {
    text-decoration: none;
}

@media #{$small-down} {
    .sectionPrestations{
        justify-content: center;
         height: auto;
       
    } 

}