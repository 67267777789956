@import '../../style/communs.scss';

div.containerNav a {
    text-decoration: none;
    font-size: 20px ;
    margin: 0px 10px;
    padding: 10px 10px;
    position: relative;
    z-index: 0;
    cursor: pointer;
  }

@media #{$medium-down} {
    .containerNav{
      display: flex;
      flex-direction: column;
    }}

    //theme
html.dark{
div.containerNav a {
    color: white;}

}    