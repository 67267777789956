@import '../../style/communs.scss';

header {
  display: flex;
  justify-content:space-around;
  align-items:center;
 flex-wrap: wrap;
 margin-top:40px;
 margin-bottom:40px;
}

.containerLogoHeader img {
  object-fit: cover;
  width: 280px;
  height: 280px;
  border-radius: 30px;
}

