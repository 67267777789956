@import "../../style/communs.scss";

.containerPresentation{
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bloc1{
  display: flex;
  flex-wrap: wrap;
  
  }
.nameDev{
  font-size: 30px;
}
.name{
  font-size: 25px;
}
.infoDev{
  line-height: normal;
  display: flex;
  flex-direction: column;
justify-content: space-around;
height: auto;

}
.contactMe{
    border: white 1px solid;
    border-radius:10px;
    margin: 10px;
    padding:10px;
    text-decoration: none;
    color: white;
    &:hover{
        border: #00b8ff 1px solid;  
        color: #00b8ff;
    }
}
.descriptif ul{
    list-style-type : none;
}
@media #{$small-down} {
 
  .nameDev{
    font-size: 20px;
  }
  .name{
    font-size: 15px;
  }
.imageContainer img{
  width: 350px;
}
.nameDev{
  margin-left: 10px;
  margin-right: 10px;
}
.name{
  margin-left: 10px;
  margin-right: 10px;
}
.basicInfo{
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.descriptif {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}
}