@import '../../style/communs.scss';
#contactMe{
 // background-color: yellow;
  padding: 60px;
}
.containerContact{
          width: 100%;
          font-size: 30px;
          font-weight: bold;
          color: white;
          padding-bottom: 10px;
   }
 
  .containerForm {
    /* Ajouter box-sizing */
    box-sizing : border-box;
    border-radius:5px;
    background-color:#f2f2f2;
    padding:20px;
    width: 100%;
    /* redéfinition 400 + 2*20 */
    max-width: 900px;
    margin:0 auto;
  }
  h1  {
    color: black;
    text-align: center;
  }

  iframe{
    width: 100%;
    height: 570px;
  }