@import "../../style/communs.scss";

.bannerTxt {
   border-top: 1px solid $colorTitle;
  border-bottom: 1px solid $colorTitle;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  text-align: center;
 padding-top: 30px;
 padding-bottom: 30px;
 height: 400px;
}

.containerAccroche,
.demandedevisTxt {

  font-weight: 900;
  font-style: italic;
  text-align: center;
}

.containerAccroche {
  font-size: 40px;
 }
.demandedevisTxt {
  font-size: 30px;
}
@media #{$small-down} {
  .bannerTxt {
    height:auto;
 // border-top: none;
border-bottom: none;
  box-shadow: none;
}}

//theme
html.dark{
  .containerAccroche,
.demandedevisTxt {
  color: white;
}}
html.light{
  .containerAccroche,
.demandedevisTxt {
  color: black;
}}