@import "../../style/communs.scss";
footer {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.containerLogoFooter img {
  border-radius: 10px;
  margin-top: 50px;
  height:160px;
  width:160px;
}
.containerNavFooter {
  position: relative;
  font-size: 12px;
}
div.containerNavFooter {
  padding: 50px;
  text-align: center;
}

div.containerNavFooter a {
  text-decoration: none;
  margin: 0px 10px;
  padding: 10px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}



@media #{$small-down} {

  footer {
    border-bottom: none;
    box-shadow: none;
  }
  .container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .container a {
    font-size: 8px;
  }
}

html.dark{
  .containerLogoFooter img {
    border: 1px solid white;}

    div.containerNavFooter a {
      color: #fff;}
      div.borderYtoX a:before,
      div.borderYtoX a:after {
        background: #fff;}
}
html.light{
  .containerLogoFooter img {
    border: 1px solid black;}

    div.containerNavFooter a {
      color: black;}
      div.borderYtoX a:before,
      div.borderYtoX a:after {
        background: black;}
}