.containerError{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: white; 
border: white solid ;
text-align: center;
height:350px;  
}
.codeError{
    font-size:30px;
    margin-bottom:20px;
}
.messageError{
    margin-bottom:20px;
}
.lienAccueil a {
    text-decoration:none;
    color: white;
}

.arrow{
    background-color: white;
    border-radius:10px;
   cursor:pointer; 
    width:50px;
    height:auto;
    margin-top:50px;
}